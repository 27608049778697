<template>
    <header>
        <nav class="nav contenedor">
            <router-link to="/">
                <img src="/img/logo-oscuro.svg" alt="Logo Todolimpias">
            </router-link>
            <ul class="enlaces-items">
                <li class="enlace-item">
                    <router-link to="/nosotros" class="enlace enlace--nosotros">Nosotros</router-link>
                </li>
                <li class="enlace-item">
                    <router-link to="/productos" class="enlace enlace--productos">Productos</router-link>
                </li>
                <li class="enlace-item">
                    <router-link to="/contacto" class="enlace enlace--contacto">Contacto</router-link>
                </li>
            </ul>
        </nav>
        <slot></slot>
    </header>
</template>