<template>
    <footer class="pie-pagina">
        <div class="contenedor columnas">
            <router-link to="/" class="columna columna--logo">
                <img src="/img/logo-claro.svg" alt="Logo de Todolimpias">
            </router-link>
            <div class="columna columna--paginas">
                <strong>Páginas:</strong>
                <router-link to="/nosotros">Nosotros</router-link>
                <router-link to="/productos">Productos</router-link>
                <router-link to="/contacto">Contacto</router-link>
            </div>
            <div class="columna columna--documentos">
                <strong>Documentos:</strong>
                <a href="/catalogo-2022.pdf" target="_blank">Catálogo en Línea</a>
            </div>
        </div>
    </footer>
</template>