<template>
    <div>
        <cabecera>
            <section class="contenedor no-encontrada__contenido">
                <h1>Página no Encontrada (404)</h1>
                <p>No se ha encontrado la página solicitada... :(</p>
            </section>
        </cabecera>
        <pie></pie>
    </div>
</template>
<script>
import cabecera from '@/components/header.vue';
import pie from '@/components/footer.vue';
export default {
    components: {cabecera, pie}
}
</script>